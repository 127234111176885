import React, { useCallback, useEffect, useState } from 'react';

import { Button, DatePicker, Flex, Form, Input, Select, Upload } from 'antd';

import { getDistricts } from '@modules/news/domain/store/slice';
import { useSelect } from '@modules/news/services/useSelect/useSelect';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';

import { useAppDispatch } from '../../../store/hooks';

interface INewsCreateFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

const { TextArea } = Input;
const { Dragger } = Upload;

export const NewsCreateForm: React.FC<INewsCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const dispatch = useAppDispatch();

  const { filterDistricts, selectMapperWithoutAll } = useSelect();
  const [descriptionValue, setDescriptionValue] = useState('');

  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    onSave({ ...value, content: descriptionValue });
    form.resetFields();
  };
  const onChangeDescription = useCallback((value: string) => {
    setDescriptionValue(value);
  }, []);

  useEffect(() => {
    dispatch(getDistricts());
  }, []);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="newsCreateForm"
      layout="vertical"
    >
      <Form.Item name="date" label="Дата" rules={[{ required: true }]}>
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="district" label="Район" rules={[{ required: true }]}>
        <Select
          showSearch
          allowClear
          placeholder="Выберите район"
          optionFilterProp="children"
          filterOption={filterDistricts}
          options={selectMapperWithoutAll}
        />
      </Form.Item>
      <Form.Item name="content" label="Содержание" rules={[{ required: true }]}>
        <TiptapEditor onChange={onChangeDescription} />
      </Form.Item>
      <Form.Item initialValue={{}} name="photo" valuePropName="photos">
        <Dragger
          {...{
            name: 'file',
            multiple: true,
            beforeUpload: () => false,
          }}
        >
          <div className="flex justify-center items-center gap-[11px] h-[88px]">
            <p className="ant-upload-hint">
              Перетащите файлы, чтобы прикрепить их или выберите
            </p>
          </div>
        </Dragger>
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
