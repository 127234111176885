import React, { useCallback, useEffect, useState } from 'react';

import { Button, DatePicker, Flex, Form, Input, Select, Upload } from 'antd';

import { INews } from '@modules/news/domain/interface/interface';
import { NewsStateSelector } from '@modules/news/domain/store/selector';
import { getDistricts, getNews } from '@modules/news/domain/store/slice';
import { useSelect } from '@modules/news/services/useSelect/useSelect';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';
import { SubmitButton } from '@ui-kit/button';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface INewsCreateFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

const { TextArea } = Input;
const { Dragger } = Upload;

export const NewsEditForm: React.FC<INewsCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { currentNews, language } = useAppSelector(NewsStateSelector);
  const [fileList, setFileList] = useState<any[]>([]);
  const [descriptionValue, setDescriptionValue] = useState('');

  const { filterDistricts, selectMapper } = useSelect();

  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    if (!currentNews) return;

    onSave({ ...value, code: currentNews.code, content: descriptionValue });
    form.resetFields();
  };

  const onChangeCurrentNews = (news: INews) => {
    const files = news.photo.map((item) => ({
      uid: item.id,
      name: item.name,
    }));

    form.setFieldsValue(news);
    setFileList(files);
  };

  const handleFileChange = ({ fileList: files }: any) => {
    setFileList(files);
    return files;
  };

  const onChangeDescription = useCallback((value: string) => {
    setDescriptionValue(value);
  }, []);
  useEffect(() => {
    dispatch(getDistricts());
  }, []);

  useEffect(() => {
    if (!currentNews) return;

    dispatch(getNews({ lang: language, code: currentNews.code }));
  }, [language]);

  useEffect(() => {
    if (!currentNews) return;

    onChangeCurrentNews({
      ...currentNews,
      date:
        currentNews.date !== null
          ? dayjs(currentNews.date).add(1, 'day').utc()
          : null,
    });
  }, [currentNews]);

  return (
    <Form onFinish={onFinish} form={form} name="newsEditForm" layout="vertical">
      <Form.Item name="date" label="Дата">
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="content" label="Содержание" rules={[{ required: true }]}>
        <TiptapEditor
          initialValue={currentNews?.content ?? ''}
          onChange={onChangeDescription}
        />
      </Form.Item>
      <Form.Item name="district" label="Район" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder="Выберите район"
          optionFilterProp="children"
          allowClear
          filterOption={filterDistricts}
          options={selectMapper}
        />
      </Form.Item>
      <Form.Item
        name="photo"
        getValueFromEvent={handleFileChange}
        valuePropName="photo"
      >
        <Dragger
          {...{
            name: 'file',
            multiple: true,
            beforeUpload: () => false,
            fileList: [...fileList],
          }}
        >
          <div className="flex justify-center items-center gap-[11px] h-[88px]">
            <p className="ant-upload-hint">
              Перетащите файлы, чтобы прикрепить их или выберите
            </p>
          </div>
        </Dragger>
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <SubmitButton form={form} />
        </Flex>
      </Form.Item>
    </Form>
  );
};
